@import '~@ng-select/ng-select/themes/default.theme.css';

/* ng-select */
.ng-select.custom {
  font-size: 14px;
  color: var(--l_grey);
  border-radius: 0;
}
.ng-select.with-checkbox .ng-dropdown-panel .ng-dropdown-panel-items .ng-option  {
  display: inline-flex;
  align-items: center;
  line-height: 0;
}
.ng-select.with-checkbox .ng-select-container .ng-value-container .ng-placeholder {
  top: 0;
  position: relative !important;
}


.ng-select .ng-select-container {
  min-height: 40px;
  border-color: var(--l_grey);
  border-radius: 0;
}

.ng-select.custom .ng-select-container .ng-value-container {
  padding-left: 16px;
}

.ng-select.custom .ng-select-container .ng-value-container .ng-input {
  top: 10px;
  padding-left: 16px;
}

.ng-select.custom .ng-arrow-wrapper {
  padding-right: 12px;
}

.ng-select.custom .ng-arrow-wrapper .ng-arrow {
  display: block;
  top: 0;
  margin: auto;
  width: 12px;
  height: 6px;
  border: none;
  background: url(./assets/img/arrow-down_dark.svg) center no-repeat;
  background-size: contain;
}

.ng-select.custom.ng-select-opened > .ng-select-container .ng-arrow {
  transform: rotate(180deg);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: rgba(0, 0, 0, 0.03);
}

.ng-select.custom.ng-select-focused:not(.ng-select-opened)
  > .ng-select-container {
  border-color: var(--primary);
  box-shadow: none;
}

.custom
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected,
.custom
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  background: rgba(0, 0, 0, 0.05);
}
/* END ng-select */

.table tbody tr td {
  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  line-height: 1.28571;
}

.main-content {
  min-height: 100vh;
}

.header-account__link.active {
  color: var(--a-active);
}

.filter-components {
  z-index: 10;
}

.nav-btns__btn {
  margin-left: 5px;
}

select {
  background-color: transparent;
}

.datepicker-container {
  z-index: 10 !important;
}

.btn-dots {
  min-width: auto;
}

.pa-pagination__item button {
  width: auto;
  min-width: 4rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

[data-authorized-user-only] {
  display: none !important;
}

.authorized-user [data-authorized-user-only] {
  display: block !important;
}

.mcard .mcard__body div:nth-child(2) {
  display: initial;
}

.accordion:not(.is-active) .mcard__name {
  display: inline-block;
  max-width: 100%;
}

.accordion:not(.is-active) .mcard-mini__name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  max-width: 21rem;
}

.ui.selection.dropdown > .text,
.ui.selection.dropdown .menu > .item {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.error .error__message {
  color: lightcoral;
  font-size: 1.25rem;
  margin-top: 4px;
}

.error .ui.selection.dropdown.form-dropdown {
  border-color: lightcoral;
}

.inline {
  display: inline !important;
}

.inline-block {
  display: inline-block !important;
}

.block {
  display: block !important;
}

.w-full {
  width: 100% !important;
}

/* .offers-item__line,
.mcard__ci,
.mcard__nav-btn,
.modcard__body dd,
.mcard-mini__name,
.mcard27__price,
.mcard49__price,
.sticker,
.ptrades__header-text,
.ptrades__list-r,
.offers-header__title,
.offers-body__desc-end,
.offers-item__texts li,
.participant__name,
.filter-components .content .filter-form__title,
.table thead th,
.pa-nav__title,
.pa__chat-title,
.t-list__num,
.stitle,
.nav-btns__btn,
.dl__dd,
.item-notice.unread .item-notice__link {
  font-weight: 500 !important;
} */

.tst-tooltip {
  color: #235 !important;
  background-color: #fff !important;
  pointer-events: none;
  text-transform: none;
  text-align: center;
  text-shadow: none;
  border-radius: 0 !important;
  font-size: 1.4rem !important;
  line-height: 1.4285em !important;
  max-width: none;
  padding: 0.833em 1em !important;
  font-weight: 400;
  font-style: normal;
  -webkit-box-shadow: -2px 4px 20px rgba(2, 8, 21, 0.1);
  box-shadow: -2px 4px 20px rgba(2, 8, 21, 0.1);
  position: relative !important;
  bottom: -3px !important;
  left: 0 !important;
  z-index: 1900;
}

.ucwords {
  text-transform: lowercase;
}

.ucwords::first-line {
  text-transform: capitalize;
}

.ellipsis {
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

[data-tooltip]:before,
[data-tooltip]:after {
  display: none;
}

.offers-item__line {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6rem;
  margin-bottom: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tt {
  position: fixed;
  max-width: 40rem;
  z-index: 1000000;
  padding: 0.833em 1em;
  -webkit-box-shadow: -2px 4px 20px rgb(2 8 21 / 10%);
  box-shadow: -2px 4px 20px rgb(2 8 21 / 10%);
  font-size: 1.4rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.4285em;
  color: #235;
  background-color: #fff;
  pointer-events: none;
  visibility: hidden;
  /* transform: scale(.5); */
  /* transition: transform .05s ease; */
}

b,
strong {
  font-weight: 600 !important;
}

.user-content p {
  margin-bottom: 10px !important;
  font-size: 14px;
  line-height: 22px;
}

.user-content ul,
.user-content ol,
.user-content ol li,
.user-content ul li {
  list-style: revert;
  font-size: 14px;
}

.user-content ul,
.user-content ol {
  padding-left: 40px;
  margin: 16px 0;
}

.user-content .title {
  font-weight: 500;
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 5rem;
}

.user-content .stitle {
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 3rem;
  margin-top: 5rem;
  /* text-transform: uppercase; */
}

.user-content .page-preview .pp {
  width: 100px;
  height: 130px;
  overflow: hidden;
  padding: 3px;
  margin-top: 5px;
  font-size: 4px;
  line-height: 1.2;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  cursor: pointer;
  border: solid 1px transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.user-content .page-preview .pp:hover {
  border: solid 1px #cfcfcf;
}

.user-content .page-preview > div > div:last-child {
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.user-content .page-preview p,
.user-content .page-preview ul li,
.user-content .page-preview ol li {
  font-size: 3px !important;
  line-height: 0.9;
  margin-bottom: 1px !important;
}

.user-content .page-preview ul,
.user-content .page-preview ol {
  margin: 0;
  padding-left: 3px;
}

.user-content .page-preview .stitle {
  font-size: 4px !important;
  line-height: 0.9;
  margin-bottom: 1px !important;
  margin-top: 0 !important;
}

.user-content .page-preview .title {
  font-size: 5px !important;
  line-height: 0.9;
  margin-bottom: 2px !important;
  margin-top: 0 !important;
}

.user-content .page-preview .table table,
.user-content .page-preview .table thead th,
.user-content .page-preview .table tbody tr td {
  font-size: 3px !important;
  padding: 0 !important;
  line-height: 0.9;
}

.user-content .page-preview .table tr.pseudo,
.user-content .page-preview .table tr.pseudo:first-child {
  height: 1px;
}

.link {
  color: var(--a-normal);
  text-decoration: underline;
}

.light-theme .offers-header__item_title {
  background-color: #235 !important;
}

.error [type='text'],
.error [type='email'],
.error [type='tel'],
.error textarea,
.error .form__group,
.error .ch__icon {
  border-color: lightpink;
  color: lightcoral;
}

.error .error__message {
  color: lightcoral;
  font-size: 0.9em;
  margin-top: 4px;
}

.error__common-message {
  color: lightcoral;
  font-size: 1em;
  text-align: center;
}

/*.pa-content {
  max-width: 100%;
}*/
.ma-table thead th,
.ma-table tbody tr td {
  padding: 2rem 1rem;
}

.ma-table .ch__content {
  justify-content: center;
}

.scroll::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 4rem;
  background-color: #e4e5e7;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #679ceb;
  border-radius: 4rem;
}

.tab {
  padding: 1.2rem;
  border-color: #ccc;
  border-style: solid;
  border-width: 0 0 1px;
  text-align: center;
  color: #4e5862;
  min-width: 25rem;
  background-color: #f8f8fa;
}

.tab-active {
  border-width: 1px 1px 0;
  color: #235;
  background-color: #fff;
}

.tab-empty {
  background-color: #fff;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.max-w-7xl {
  max-width: 80rem;
}

.min-w-7xl {
  min-width: 80rem;
}

.w-7xl {
  width: 80rem;
}

.hidden {
  display: none;
}

.justify-center {
  justify-content: center;
}

.w-4 {
  width: 1rem;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.popup__body {
  position: relative;
  background-color: #fff;
  margin: auto;
  max-height: 90vh;
  min-width: 50vw;
  max-width: 90vw;
  overflow: auto;
  padding: 5rem;
  -webkit-box-shadow: 0 9px 70px rgba(0, 0, 0, 0.05);
  box-shadow: 0 9px 70px rgba(0, 0, 0, 0.05);
}

.popup__body::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 4rem;
  background-color: #e4e5e7;
}

.popup__body::-webkit-scrollbar-thumb {
  background-color: #679ceb;
  border-radius: 4rem;
}

.popup__close {
  position: absolute;
  top: 3rem;
  right: 3rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  text-indent: -9999px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("data:image/svg+xml, %3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5 1.5L1.5 16.5' stroke='%23020815' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.5 1.5L16.5 16.5' stroke='%23020815' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  -webkit-transition: -webkit-transform 0.25s linear;
  transition: transform 0.25s linear;
  -o-transition: transform 0.25s linear;
  transition: transform 0.25s linear, -webkit-transform 0.25s linear;
}

.popup__close:hover {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  color: #000;
  cursor: pointer;
}

.popup__close:focus {
  color: #000;
  cursor: pointer;
}

.popup__opened {
  display: flex;
}

body {
  margin: 0;
}

.rotated {
  transform: rotate(180deg);
}

.sort-chevron {
  transition-property: transform;
  transition-duration: 0.25s;
}

.main-content .selection-filter__item p {
  margin-bottom: 0 !important;
}

.package-list {
  overflow-y: hidden;
}

/* ToDo: remove */
/* Hide modal close button */
#modal-trade-time .close-modal,
#modal-trade-time .modal-close,
#dealer-auth-step-three .close-modal,
#dealer-auth-step-three .modal-close,
#modal-trade-canceled .close-modal,
#modal-trade-canceled .modal-close,
#modal-change-package-price .close-modal,
#modal-change-package-price .modal-close,
#change-price-modal .close-modal,
#change-price-modal .modal-close,
#agreement-modal .close-modal,
#agreement-modal .modal-close,
#set-time .close-modal,
#set-time .modal-close,
#registration-warning .close-modal,
#registration-warning .modal-close {
  display: none;
}



.progress-bar{
  border: 1px solid #E4E5E7;
  background: #FFFFFF80;
  height: 20px;
 
  gap: 10px;
  border-radius: 19px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  margin-right: 8px;

}

.progress-line{

  height: 20px;
  padding: 4px 20px 4px 20px;
  gap: 10px;
  border-radius: 50px;
  opacity: 0px;
  background: #F58A00;
  
  font-family: Gotham Pro;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
  text-wrap: nowrap;
  display: flex;
  justify-content: center;
}

.progress-line-100-percent{
  background: #20C87E;
}

.w-100-percent{
  width: 100%;
}

.orange-color-text{
  color: #F58A00;
  
 
  .dl__points{
    border-color: #F58A00 !important;
  }
}

.red-color-text{
  color: #EB5757;
 
  .dl__points{
    border-color: #EB5757 !important;
  }
  
}

.jconfirm-content{
  text-align: center;
  padding: 0 50px;
}


.connection-amount-text-requistion{
  font-family: Open Sans;
font-size: 12px;
font-weight: 600;
line-height: 14.4px;
text-align: left;
margin-bottom: 4px;
color:#223355;
}